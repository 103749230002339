export const environment = {
  production: false,
  feUrl: 'https://platform.dev.madsense.io',
  serviceUrl: 'https://social-api.dev.madsense.io/api',
  madBuyServiceUrl: 'https://madbuy-be.dev.madsense.io/api',
  madBuyReportingServiceUrl: 'https://madbuy-reporting.dev.madsense.io/api',
  metaConfigId: '1332932611064092',
  metaAdsConfigId: '600972969233361',
  metaInstagramConfigId: '1811961516246690',
  metaAppId: '988066272734542',
  client_id: '781k0u4pah1tr5',
  meta_company_id: ['b738dabd-8bb1-4cfa-9bc8-0c568a6873dc'],
  redirect_uri: 'https://social-api.dev.madsense.io/api/social-media-connections/linkedin',
  scope: 'w_member_social%2copenid%2cemail%2cprofile',
  sentryDsn: 'https://4f570a259cf3af55b75eea3b9068dbeb@o4507844150427648.ingest.us.sentry.io/4507866009829376',
  wsUrl: 'wss://social-api.dev.madsense.io',
  wsConfig: {
    endpoint: '/ws',
    queuePath: '/user/queue/specific',
    reconnectDelay: 5000,
    heartbeatIncoming: 10000,
    heartbeatOutgoing: 10000,
    competitorsPath: '/user/agent-job'
  }
};
